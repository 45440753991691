/* @font-face{
    font-family:"cairo-bold";
    src:url("https://candyfonts.com/wp-data/2019/03/18/37752/Cairo-Bold.ttf") format("woff"),
    url("https://candyfonts.com/wp-data/2019/03/18/37752/Cairo-Bold.ttf") format("opentype"),
    url("https://candyfonts.com/wp-data/2019/03/18/37752/Cairo-Bold.ttf") format("truetype");
} */
.bgimg{
    background-image: url('./Images/Group 91.jpg');
    background-size: 100% 100%;
    width: 100%;
    height: 520px;
    
}
.business{
    background-image: url('./Images/Rectangle 18.jpg');
    background-size: 100% 100%;
    width: 100%;
    /* max-width: 100%; */
    /* height: 200px; */
   height: 260px;
    margin-top: -50px;
    
}
.business1{
    background-image: url('./Images/Group 78.jpg');
    background-size: cover;
    width: 100%;
     height: 370px;
    /* margin-top: -50px;  */
    /* max-width: 100%; */
    /* height: 200px; */
   /* height: 260px;
    margin-top: -50px; */
    
}
.business1 h1{
    color:#003366
}
.loanimg{
    background-image: url('./Images/Rectangle 13.png');
    background-size: cover;
    width: 100%;
    /* height: 500px; */
    
}
.immediateimg{
    background-image: url('./Images/Rectangle 32.jpg');
    background-size: cover;
    width: 100%;
    height: auto;
    /* min-height: 480px; */
    /* height: 475px; */
    /* opacity: 0.8; */
  
    /* box-shadow: inset 0 0 0 200px rgb(222, 34,69); */
    
}
.button{
    position: absolute;
    top:42%;
    right: 0%;
    transform: rotate(90deg);
    height: 90px;
}
.callback{
    
        background-color: #E02B59;
        font-weight: bold;
    }

.icon{
    position: absolute;
    top: 60%;
    right: 0%; 
    
    /* padding-left: 1250px;  */
    
}
.icon img{
    height: 32px;
    width: 100%;
}

.disbursed img{
    width: 15%;
}
.disbursed h1{
    color: #E02B59
}
.apply{
    background-color: #E02B59;
    border-radius: 30px;
    /* width: 13%; */
    font-weight: bold;
}
.apply3{
    background-color: #E02B59;
    border-radius: 30px;
    /* width: 35%; */
    font-weight: bold;
    margin-left: -220px;
}
.hearderset{
    padding-top: 90px;
    box-sizing: border-box;
   
}
.hearderset h1{
    font-size: 30px;
}
.hearderset1{
    padding-top: 90px;
    box-sizing: border-box;
   
}

.layer{
background-color: rgba(12, 12, 0, 0.685);
/* position: absolute; */
top: 0;
left: 0;
width: 100%;
height: 100%;
/* opacity: 0.9; */
}
/* .layer1{
    background-color:  rgba(255, 0, 55, 0.671); 
   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    } */
  
.section img{
 width:30%;
}
.vertical-line{
    width: 1px;
    /* height: 100px; */
    /* background-color: black; */
    border-left: 2px dotted #444;
}

.verticalline{
    width: -1px;
    /* height: 100px; */
    /* background-color: black; */
    border-left: 2px solid silver;
}

hr.style1{
    width: 197px;
	border-top: 2px solid silver;
}
.money1{
    display: block;
}
.money1 img{
    border-radius: 50%;
    width: 119%;
    height: 99%;
}

.flex-container {
    display: flex;
    justify-content: center;
   
    
  }

  .guarantors-row img{
      width: 100%;
      /* border-radius: 30px; */

  }

  .flex-container > div {
    background-color: #f1f1f1;
    width: 150px;
    margin: 12px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
   
  }

  .section1 img{
      width: 110%;
  }
  .business h1{
     color: #003366;
     font-family: 'Cairo';
     /* margin-top: -2%; */
/* justify-items: auto; */
padding-left: 420px;
    
     font-size: 35px;
     /* margin-top: -300px;0 */
  }
  .site-footer{
   
      padding: 45px 0 20px;
      font-size: 15px;
      line-height: 24px;
      border-radius: 12px;
  }
  .site-footer h6{
      color: #003366;
      font-size: 20px;
      margin-top: 5px;
      font-family: 'Cairo Bold', sans-serif;
      font-weight:bolder;
      letter-spacing: 1px;
  }
  .site-footer h5{
     font-size: 100%;
    font-weight: bolder;
 }
 .site-footer p{
   font-family: 'Cairo';
 }
  .footer-links a{
      color: black;
   
  }
  .footer-links li{
    display: block;
    font-family: 'Cairo', sans-serif;
    line-break: normal;
}
.immediate_needs span{
    font-size: 120%;
}
.immediate_needs h4::after{
    content: '';
    background:darkblue; 
    display: block;
    height: 3px;

    width: 580px;
    margin: 17px  1px;
}
.meetheading{
    margin-bottom: -20px;
}
.meetheading h5{
    font-family: 'Cairo', sans-serif;
    font-weight: 550;
    font-size: 28px;
}
.meetheading h5::after{
    content: '';
    background:#E02B59;
    display: block;
    height: 3px;
    width: 600px;
    margin: 17px  1px;
}
.copyright1 p{
margin-left: -130px;
}
.copyright2 p{
    margin-left: 70px;
}
.apply4{
    margin-right: 90px;
    background-color: #E02B59;
    border-radius: 30px;
    /* width: 60%; */
    font-weight: bold;  

}
.apply2{
    margin-left: 15px;
    background-color: #E02B59;
    border-radius: 30px;
    /* width: 13%; */
    font-weight: bold;
}
 hr.one{
    border: 2px solid black;
width: 200px;
    background:black;
}
.guarantors-row{
    margin-left: -97px;
}
.installments {
    width: 80%;
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow:
    /* inset 0 -3em 3em rgba(0,0,0,0.1), */
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(0,0,0,0.3);
    /* border-color: rgba(111,111,111,0.2) transparent transparent;   */
}
.partners{
    width: 100%;
   
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
}
.partners img{
    width: 100%;
    height: 100px;
   
}
.partner-row img{
    margin-left: 100px;
}
.footerimg{
    width: 10%;
}
.site-footer h1{
color: #003366;
/* font-size: 50px; */
}



.footerimg img{
width: 190%;
}
.btn1{
display: none;
}

.why-myfin h1{
    font-size: 25px;
}
span{
    color: #003366;
}
.why-myfin h1::after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 220px;
    margin: 10px auto 1px;
     
  }
 
  /* .partners h4::after{
    content: '';
    background: white; 
    display: block;
    height: 2px;
    width: 150px;
    margin: 10px auto 1px;
     
  } */
  .money2{
      display: none;
  }
   .immediate_needs h4 {
       
               font-family: 'Cairo';
                font-size:31px;
                text-transform:none;
                color:#E02B59;
                
            }
            .immediate_needs h6{
                font-family: 'Cairo', sans-serif;
                font-weight: smaller;
            }
            .lastfooter{
                background-color: #0D4988;
            }
            /* .footerlogo img{
                width: 28%;
                
            } */
            .imgdiv img{
                width: 50%;
                margin-left: -180px;
            }
            .imgdiv h1{
                font-size: 26px;
            }
           .apply5{
            margin-top: -125px;
           
            background-color: #E02B59;
            border-radius: 30px;
            
            font-weight: bold;  
        }
        .needsrow img{
            width: 16px;
        }
    /* .style {
        background-color: #F32951;
    }
           */
  /* @media(max-width: 1024px){ */
    @media screen and (max-width: 768px){
        /* .immediateimg{
          height: 900%;
        } */
        .footerimg img{
            width: 120%;
            }
        .imgdiv img{
         margin: auto auto auto auto;
         width: 100%;
        }
       
           .apply5{
               margin-top: -125px;
              
               background-color: rgb(222, 34,69);
               border-radius: 30px;
               /* width: 60%; */
               font-weight: bold;  
           }
          
            /* .site-footer1 img{
                width: 1000%;
            } */
        .bgimg{
            height: 570px;
        }
    /* .hearderset{
        padding-top: 180px;
    } */
    .meetheading h5{
        font-size: 25px;
    }
    .immediate_needs h4{
        font-size: 30px;
    }
    .immediate_needs h4::after{
        width: 90%;
    }
    
    .section1 img{
        display: none;
    }
    .why-myfin h1{
        font-size: 25px;
    }

    /* .hearderset1{
        box-sizing: border-box;
    } */
    .hearderset h1{
        font-size: 22px;
      
    }
    .hearderset h4{
        font-size: 20px;
    }
    .heading {
        padding-top: 130px;
    }
  
    .hearderset1 h1{
        font-size: 20px;
        /* margin-left: 30px; */
    }
    .why-myfin h6{
        font-size: 12px;
    }
   
   /* .apply4{
  margin: auto auto auto auto;
 
    }*/
    .money1 img{
        border-radius: 50%;
        width: 119%;
        height: 99%;
    }
    .money2{
        display: block
    }
    .money2 img{
        position: relative;
        top: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        height: 270px;
        width: 340px;
        background-size: cover;
        margin: auto auto auto auto;
        border-radius: 130px 130px 0 0;
   
    }
    .installments{
        width: 100%;
        /* height: 150px; */
    }

    .needs{
        margin-top: -2.1em;
    }
    .flex-container {
        display: -ms-flexbox;
        justify-content: center;  
      }
      .flex-container > div {
        background-color: #f1f1f1;
        width: 150px;
        margin: 12px;
        text-align: center;
        line-height: 75px;
        font-size: 30px;
        display: grid;
       
      }
      .partner-row img{
        margin-left: auto
    }
    .last-row{
        display: none;
    }
    .site-footer1 img{
        width: 50%;
    }
    .apply2{
        margin-left: 15px;
        background-color: rgb(222, 34,69);
        border-radius: 30px;
        /* width: 45%; */
        font-weight: bold;
    }
    .guarantors-row img{
        width: 70%;
       
    }
    .guarantors-row{
        margin-top: -22px;
    }

        .apply3{
            /* width: 45%; */
            margin: auto auto auto auto;
        }
      
      .copyright1 p{
        margin: auto auto auto auto;
        }
        .copyright2 p{
            margin: auto auto auto auto;
            }

            .immediate_needs h6{
                color: black;
                padding-left: 15px;
            }
            .verticalline{
                display: none;
            }
            hr.style1{
              display: none;
            }
            /* .business{
                background-image: url('./Images/Rectangle 18.jpg');
                background-size: cover;
                
                width: 100%;
               
                height: 150px;
               
                
            } */
       
            .business h1{
                font-size: 22px;
                margin-bottom: 100px;
                padding-left: 250px;
            }
            .why-myfin h1::after{
            width: 60%;
                 
              }
              .need h5::after{
                content: '';
                background: rgb(222, 34,69); 
                display: block;
                height: 2px;
                width: 60%;
                margin: 10px auto 17px;
                 
              }
              .meetheading h5::after{
                width: 80%;
                margin-right: 50px;
              }
              .guarantors-row{
                margin-left: -20px;
            }
            .connect{
                margin: auto auto auto auto;
            }
            /* .disbursed{
                display: block;
            } */
               
            .disbursed img{
                width: 20%;
                
            }
            .partners{
                display: none;
            }
            .immediate_needs h6:before{
                color: darkblue;
            }
    
            
}
@media(min-width: 1024px) and (max-width:1024px){
    .business h1{
        font-size: 28px;
       padding-top: 25px;
    }
    .immediate_needs h4::after {
        width: 100%;
    }
    .immediate_needs h4{
        font-size: 29px;
    }
    .meetheading h5{
        font-size: 22px;
    }
    .meetheading h5::after{
        width: 96%;
    }
    .money1 img{
width: 130%;
    }
    .money1 img{
        border-radius: 50%;
        width: 142%;
        margin-left: -33px;
       
    }
}
@media only screen and (min-width: 768px) and (max-width:1023px){
    .needsrow img{
     margin-right: 500px;
    }
    .immediate_needs h4 {
         font-size:22px;
     }
     .meetheading h5{
   margin-left: -10px;
    font-size: 22px;
}
.money1 img{
    border-radius: 50%;
    width: 140%;
    margin-left: -22px;
   
}

}