/* product banner image */
.productimg{
    background-image: url('./Images/handshake_women.png');
    background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 340px;
    margin-top: -17px;
    
}
.producthearderset{
    padding-top: 50px;
}
.producthearderset h1{
font-size: 44px;
}
 .producthearderset h1::after{
    content: '';
    background: #E02B59;
    display: block;
    margin-top: 150px;
    height: 4px;
    width: 380px;
    margin:  auto 1px;
      
 }
.traingleproduct{
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -343px;
    margin-left: 251px;

}
.traingleproduct::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}

/* solution */

.solution img{
    width: 50%;
}
.solution h3::after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 300px;
    margin: 10px auto 1px;
     
}

/* immediate1img */
.immediate1img h4{
color: #E02B59;
}
.immediate_needs1 h6{
   font-size: 20px;
   padding-top: 8px;
    font-weight: smaller;
}
.money3 img{
    border-radius: 50%;
    width: 130%;
    height: 90%;
}
.meetheading1 h5{
    font-size: 25px;
}
.monthcard{
    margin-bottom: -40px;
}
.monthcard .card{
    width: 98%;
    border-radius: 30px;
    border-style: solid;
    border-color: black;
    
}
.productrow{
    width: 100%;
}
.monthcard_heading{
    /* color: #003366; */
    font-size: 30px;
}
.monthcard h4{
    font-size: 30px;
}
.monthcard_heading::after {
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 670px;
    margin: 10px auto 1px;
}
.cardhr{
    width: 125%;
    margin-left: -36px;
    border-color: grey;
    border-style: solid 1px;
   
}
.documentation{
    padding-bottom: 262px;
}
.price .card{
    height: auto;
}
.card-body li{
    margin-top: 1px;
   
}
 .card-header h5{
    background-color: #003366;;
    width: 92%;
    font-size: 25px;
}
.click{
    
    background-color: #E02B59;
    border-radius: 30px;
    font-weight: bold;
}
.modal-body .card{
    border: none;
    background-color: #ffffff;
    box-shadow:
   
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(0,0,0,0.5);
          width: 95%;
}
.modal-body img{
    width: 60%;
    margin-top: -140px;
}
.close:hover{
    color: white;
    text-decoration: none;
}

.modal h5{
    text-align: center;
}

.modal .fade{
    
    margin: auto auto auto auto;
}
.modal-header{
    text-decoration: none;
}
.thead th{
    border-style: solid ;
    border-color: black;
}
.modal-body .card h4{
    color: #E02B59;
}
.modal-title::after {
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 600px;
    margin: 10px auto 1px;
}
 /* 
  tbody {
    border: 1px solid black;
    padding-top: 1300px;
    box-sizing: border-box;
  }
 */
 th{
    border: 1px solid black;
    padding: 8px;
    width: 20%;
  
  }
 button:focus{
    outline: none;
}
 td{
    border-left: 1px solid black;
    border-right: 1px solid black;
}
  
  tbody {
 
    box-shadow: 0 0 0  1px black;
    
   
  }
 


  

 table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    height: 35px;
    vertical-align: bottom;
  }
  .table td{
    border-top: none;
}
.tablecard .card{
    border-radius: 40px;
}
.tablecard td{
    border-left: none;
    border-right: none;
}
.tablecard tbody{
    box-shadow: none;
}
.threemonth{
    /* margin-left: 15px; */
    /* background-color: #003366; */
    border-radius: 30px;
    font-weight: bold;
}
.threemonth::after{
    background-color: #003366;
}
.sixmonth{
    /* margin-left: 15px; */
    background-color: #003366;
    
    border-radius: 30px;
    font-weight: bold;
}
.tablecard .table tbody td{
    font-size: 14px;
}

  @media screen and (max-width: 768px){
    .productimg1{
        background-image: url('./Images/2.jpg');
        background-size: 100% 100%;
   
        width: 100%;
        height: 24rem;
        margin-top: 18px;
        
    }
    .productimg{
        display: none;
    }
    .productimg1 h4{
        color: #E02B59;
        font-size: 19px;
    }
   
    .producthearderset h1::after{
     width: 90%;  
     margin-top: 7px;
     }
     .immediate1img h4::after{
        content: '';
        background: #003366;
        display: block;
        margin-top: 10px;
        height: 4px;
        width: 97%;
        
        }
    .solution img{
        width: 40%;
    }
     .solution h3::after{
        width: 90%;
     }
     .monthcard_heading::after {
        width: 90%;
     }
     .modal-title::after {
             width: 90%;
     }

    /* .productrow{
        width: 120%;
    margin: auto auto auto auto;
    } */
    .producthearderset h2{
        font-size: 26px;
    }
    .producthearderset h1{
        font-size: 30px;
    }
    .immediate1img{
        background-image: url('./Images/Rectangle 32.jpg');
        background-size: cover;
        width: 100%;
        height: auto;
        
    }
    .monthcard h4{
        font-size: 25px;
       
    }
    .modal-body img{
        width: 60%;
        margin-top: -230px;
    }

  }
  @media(min-width: 1024px) and (max-width:1024px){
      .monthcard .card{
          height: 103vh;
      }
      .documentation{
        padding-bottom: 360px;
    }
    .cardhr{
        width: 130%;
    }
      /* .monthcard .tenorrow{
          padding-top: 78px;
      } */
  }
  @media only screen and (min-width: 768px) and (max-width:1023px){
    .solution h3::after{
        width: 40%;
    }
    .monthcard_heading::after{
        width: 75%;
    }
   .cardhr{
       width: 147%;
   }
   .monthcard .card{
       height: 162vh;
   }
   .monthcard .card-header h5{
       font-size: 18px;
   }
   .documentation {
       margin-bottom: 137%;
   }
  }