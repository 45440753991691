.otherloanpara h3::after {
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 178px;
    padding-top: 3px;
    margin-top: 5px;
   
}
.otherloanpara h5{
    font-size: 23px;
}
.loancards{
    background-color: ghostwhite;
}
.loancards .card{
    border: none;
    background-color: #ffffff;
    box-shadow:
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(0,0,0,0.3);
}
.card-title{
    color: #E02B59;
    font-size:21px;
    
}
.bullet{
    width: 40px;
}
.line{
    width: 92%;
    margin-right: 100px;
}
.cardheading::after {
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 755px;
    padding-top: 3px;
    margin: 10px auto 5px;
   
}
.accuracy::after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 720px;
    padding-top: 3px;
    margin: 10px auto 5px;
}
.ensureheading::after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 350px;
    padding-top: 3px;
    margin-top: 10px;
    /* margin: 10px auto 5px; */
   
}
.circle{
    border-radius: 50%;
}
.loansbuy{
    height: 273px;
}
.personalloan{
    height:  289px;
}
.accuracyimg{
    background-image: url('./Images/accuracyimg.jpg');
    background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 420px;
    margin-top: -17px;
    
}
.accuracyimg img{
    width:90%;
}
.homeloan, .commercial{
    height:280px;
    width:100%;
}

.loanproperty, .personalloan{
    height:300px;
    width:100%;
}
.otherloanhr{
    border-top: 1px solid grey;
    width: 1230px;
    margin-top: -1px;
    position: relative;
}
input{
    /* border: 1px solid white; */
    /* overflow: hidden; */
    height: 110%;
    border-radius: 5px;
    border: 1px solid black;
}
/* input:active{
    border: none;
    overflow: hidden;
    box-sizing: none;
    
} */
/* .no-border:active {
    border: 0;
    box-shadow: none; 
} */
/* input:focus{
    border: none;
    overflow: hidden;
    border-style: none;
    text-decoration: none;
} */


.label1{
    /* border: 1px solid black; */
    width: 92%;
    margin: auto auto auto auto;
    height: 43px;

}
.label2{
    width: 92%;;
}
.label2 label{
    font-size: 19px;
}
/* The container */
.checkmarkdiv {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  /* .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  } */
  
  /* Create a custom checkbox */

  .checkmarkdiv .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 27px;
    width: 27px;
    background-color: white;
    border: 1px solid black;
    margin-top: 4px;
  }
  /* input:focus{
    outline: none;
} */
  .checkmark1div .checkmark{
    position: absolute;
   
    height: 22px;
    width: 22px;
    background-color: white;
    border: 1px solid black;
  }
  form h5{
      color: #E02B59;
  }
  .onloanmodal h4:after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 380px;
    padding-top: 3px;
    margin: 10px auto 5px;
  }
  /* On mouse-over, add a grey background color */
  /* .container:hover input ~ .checkmark {
    background-color: #ccc;
  } */
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  .checkmark1div input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark1div input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkmark1div .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .personaldetails .netmonth {
      margin-left: -140px;
  }
  .carpet input, .estimate input, .property input{
      width: 65%;
      /* margin-left: 40px; */
  }
  .personal_loan_email{
      margin-left: 200px;
  }
.submitButton{
    background: #E02B59;
    color:white;
    border: #E02B59;
    border-radius: 30px;
    font-weight: bold;
}
.submitButton:hover{
    background: #E02B59;
    color:white;
    border: #E02B59;
    border-radius: 30px;
}
@media screen and (max-width: 768px){
    .onloanmodal h4:after{
        width: 270px;
    }
    .accuracyimg{
        background-image: url('./Images/accuracyimg_mobile.jpg');
        background-size: 100% 100%;
        position: relative;
            width: 100%;
            /* height: 650px; */
        margin-top: -17px;
    }
    .loancardhr{
        border: 1px dashed black;
    }
    .otherloanpara h3::after{
        content: '';
        background: #E02B59;
        display: block;
        height: 2px;
        width: 179px;
        margin: 10px auto 1px;
         
    }
    .otherloanpara h5{
        font-size: 18px;
    }
 

    .cardheading::after{
        display: none;
    }
    .accuracy::after{

display: none;
    }
    .ensureheadingdiv h3::after{
        content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 240px;
    margin: 10px auto 0px;
    }
   .cardheading::after{
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 200px;
    margin: 10px auto 0px;  
   }
    .accuracyimg{
        height: 70vh;
    }
    .accuracyimg .row{
        width: 100%;
    }
    .accuracy{
        font-size: 23px;
    }
    .accuracyimg img{
        width: 120%;
        
    }
    .bullet{
        width: 20px;
    }
    .loancards h4{
        font-size: 21px;
    }
    .ensureheading{
        font-size: 24px;
        text-align: center;
    }
    
       .line{
           display: none;
       }
       .loancards .card-title{
           font-size: 24px;
       }
       .accuracyimg h3::after{
        content: '';
        background: #E02B59;
        display: block;
        height: 2px;
        width: 220px;
        margin: 10px auto 0px; 
       }
       .label1 input{
           width: 130%;
           margin-left: 10%;
       }
       .label1{
           margin-left: -25px;
       }
   
}
@media(min-width: 1024px) and (max-width:1024px){
    .otherloanhr{
        width: 990px;
    }
    

}
@media only screen and (min-width: 768px) and (max-width:1023px){
    .producthearderset h1::after{
        width: 260px;
    }
    .otherloanpara h3::after{
        content: '';
        background: #E02B59;
        display: block;
        margin-top: 5px;
        padding-top: 3px;
        height: 4px;
        width: 172px;
        margin:  auto 2px;
    }
    .accuracyimg h3::after{
        width: 90%;
        content: '';
        background: #E02B59;
        height: 4px;
        padding-top: 3px;
    }
    .accuracyimg img{
         width: 80%;
         margin-top: 10px;
    }
    .ensureheadingdiv h3::after{
        width: 45%;
        height: 4px;
        padding-top: 3px;
    }
    .cardheading::after{
        width: 90%;
        height: 4px;
        padding-top: 3px;
    }
    .homeloan, .loanproperty, .commercial, .personalloan{
        height: 35vh;
    }
    .loancards .bullet{
        margin-left: 20%;
    }
}