@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
.faqimg{
    background-image: url('./Images/faq-dunod.jpg');
    background-size: 100% 100%;
position: relative;
    width: 100%;
    height: 360px;
    margin-top: -17px;
    
}
.ques button{
 color: black;
 border: none;
 background: none;

}

.btn:focus {
    box-shadow: none;
    text-decoration: none;
  }
.ques button:hover{
    color: black;
    text-decoration: none;
}
.Faq h2::after  {
    content: '';
    background:#E02B59;
    display: block;
    height: 2px;
    width: 75px;
    margin: 11px  1px;
}
.btn img{
    width: 10%;
}
.Faqhr{
    border-top: 2px dashed grey;
    width: 97%;
   
    position: relative;
}
.accordion .btn{
    font-size: 22px;
    font-weight: bold;
    margin-left: -59px;
}
.Faq h3{
    color: #E02B59;
    font-weight: bold;
}
.faqrow img{
    width: 32%;
    margin-top: 15px;
    margin-left: -30px;
}
/* #headingOne img{
    width: 3%;
    margin-left: 720px;
    margin-top: -19px;
} */
.para{
    font-size: 20px;
}
.trainglefaq{
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -363px;
    margin-left: 890px;

}
.trainglefaq::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}
.accordion .ques img{
    width: 3%;
    position: absolute;
    top: -11px;
    right: 1rem;
}
.para ul{
    margin-top:-12px;
}
/* .btn-link:after{
content: '\2212';
}
.btn-link  .collapse::after{
content: '\002B';
} */

.btn-link:after{

content:'\002B';
width: 70px;
font-size: 25px;
text-align: center;
border-radius: 5px;
right: 3px;
position: absolute;
color: gray;

}
.btn-link:not(.collapsed)::after {
    content: '\2212';
    color: gray;
    width: 70px;
font-size: 25px;
text-align: center;
border-radius: 5px;
right: 3px;
position: absolute;
}
/* .btn-link::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(.7) !important;
  }
  .btn-link:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  } */
@media screen and (max-width: 768px){
    .faqimg{
        background-image: url('./Images/faq-dunod.jpg');
        background-size: 100% 100%;
   
        width: 100%;
        height: 11rem;
        margin-top: -17px;
        
    }
    .ques h2{
        text-align: left;
    }
    .faqimg{
     
        margin-top: 8px;
        
    }
    .Faqhr{
        border-top: 2px dashed grey;
        width: 90%;
       
        position: relative;
    }
    .accordion .btn{
        font-size: 20px;
        font-weight: bold;
        margin:auto auto;
    }
  
    .faqrow img{
        width: 100%;
        /* margin-top: 15px; */
        margin-left: 30px;
    }
    .trainglefaq{
        display: none;
    }
    /* .accordion .ques img{
        width: 5%;
        position: absolute;
        top: 1px;
        right: 1rem;
    } */
    .btn-link:after
    {
        width: 5%;
        position: absolute;
        top: 1px;
        /* right: 1rem; */
        right: -9px;
    }
    .btn-link:not(.collapsed)::after
    {
        width: 5%;
        position: absolute;
        top: 1px;
        /* right: 1rem; */
        right: -9px;
    }
    .faqrow img{
        width: 1rem;
        /* margin-top: 15px; */
        margin-left: 11px;
    }
    
   
}
@media (min-width: 769px) and (max-width:1023px){
    .accordion .btn{
        margin-left: -200px;
    }
}
@media only screen and (min-width: 768px) and (max-width:1023px){
    .accordion .btn{
        margin-left: -20px;
    }
   
    .Faqhr{
        width: 95%;
    }
    .btn-link:after
    {
        width: 5%;
        position: absolute;
        top: 1px;
        right: 1rem;
        
    }
    .btn-link:not(.collapsed)::after
    {
        width: 5%;
        position: absolute;
        top: 1px;
        right: 1rem;
        
    }
}