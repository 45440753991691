.contactusimg{
    background-image: url('./Images/Group\ 286.jpg');
    background-size: 100% 100%;
position: relative;
    width: 100%;
    height: 360px;
    margin-top: -17px;
    
}
.trainglecontactus{
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -363px;
    margin-left: 980px;

}
.trainglecontactus::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}
.contact_heading{
    color: #0D4988;;
}
.working_hours{
    color: #E02B59;
}
.mapsection{
    padding-top: 100px;
    padding-bottom: 100px;
}
.map{
    background-image: url('./Images/Mask\ Group\ 6.jpg');
    background-size: 100% 100%;
position: relative;
    width: 75%;
    height: 460px;
   margin-left: 150px;
    
}
.directionbuttons {
 
        position: absolute;
        top:85%;
        right: 6%;
        /* transform: rotate(90deg); */
        /* height: 90px; */
    }
 
    .formbox{
        background-color: #E02B59;
        border-radius: 50%;
        width: 80%;
        box-sizing: 1px solid red;
    }
.contactform .heading{
    color: #E02B59;
}
.contactform .container{
    width: 80%;
    height: 50px;
    margin-left: -23%;
}
.contactform button{
    width: 73%;
    height: 50px;
    margin-left: -24%;
}

/* input:focus{
    border: none;
    overflow: hidden;
    border-style: none;
    text-decoration: none;
} */
.verticalline{
    width: 1px;
    /* height: 100px; */
    /* background-color: black; */
    border-left: 2px solid lightgray;
}
input:focus{
    outline: none;
}
.contactform .label1{
    
    /* border: none; */
    height: 30px;
}
.contactform .container input{
    margin-left: 30px;
}
::placeholder {
    color: black;
    /* padding-left: 10px; */
    padding-top: 20px;
    
  }

.contacthearderset{
    padding-top: 165px;
}
.contacthearderset h2{
font-size: 44px;
}
.contacthearderset h2::after{
    content: '';
    background: #E02B59;
    display: block;
    margin-top: 100px;
    height: 4px;
    width: 240px;
    margin:  auto 1px;
}
.directionbuttons img{
    width: 40%;
}
.directionbuttons .sharebutton{
    width: 30%;
}
.contactform  .quarybox{
    height: 14vh;
}
.imgdiv_contactus img{
    width: 45%;
    margin-left: -250px;
}
@media screen and (max-width: 768px){
    .imgdiv_contactus img{
        width: 48%;
        margin-left: 13px;
        
    }
   
    .contactusimg{

        background-image: url('./Images/Group\ 292.jpg');
        background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 360px;
        margin-top: 17px;
        
    }
    .contact_left h4{
        font-size: 20px;
    }
    .contactform h4{
        font-size: 17px;
    }
    /* .contactform .container{
        width: 85%;
        margin-left: 25px;
        margin-right: 25px;
        height: 55px;
        
    } */

    .map{
        background-image: url('./Images/MAP M.jpg');
        background-size: 100% 100%;
    position: relative;
        width: 85%;
        height: 300px;
       margin-left: 25px;
       margin-right: 40px;
        
    }
    .mapsection{
        padding-top: 65px;
        padding-bottom: 95px;
    }
    .directionbuttons {
 
        position: absolute;
        top:110%;
        right: 10%;
        /* transform: rotate(90deg); */
        /* height: 90px; */
    }
    .directionbuttons img{
        width: 50%;
    }
    .directionbuttons .sharebutton{
        width: 40%;
    }
    .contactform button{
        width: 73%;
        height: 50px;
        margin-left: -1%;
    }
    .contactform .container{
        width: 80%;
        height: 50px;
        margin-left: -1%;
    }
    .contactform  .quarybox{
        height: 15vh;
    }
    /* .contactform  input{
        
       margin-top: 20px;
       padding-left: 10mm;
    }
    .contactform .label1{
position: absolute;
        width: 20%;
        height: 10px;
    }
     */
}

@media(min-width: 1024px) and (max-width:1024px){
    .contactform  .quarybox{
        height: 7vh;
    }
    .imgdiv_contactus img{
        margin-right: -30px;
    }
    .copyright1 p{
        margin-left: 5px;
    }
    .copyright2 p{
        margin-left: 50px;
    }
    .footerimg{
        width: 20%;
    }
}
@media only screen and (min-width: 768px) and (max-width:1023px){
    .contactform  .quarybox{
        height: 8vh;
    }
    .imgdiv_contactus img{
        margin-left: -150px;
    }
    .imgdiv img{
        margin-left: -110px;
    }
}