/* company mission */
 /* "react-visibility-sensor": "^5.1.1", */
.about{
 border-top: #E02B59;
}
 .companyrow img{
    width: 17px;
    margin-top: -2px;
   
}
.abouthr{
    border: 2px solid rgb(119, 112,112);
    width: 97%;
    margin-top: 7px;
    position: relative;
}
.traingle{
    width: 0px;
    height: 0px;
    position: relative;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -20px;
    margin-left: 230px;

}
.traingle::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}
.concept1 img{
    
    width: 90%;
    height: 280px;
}


h1,h2,h3,h4,h5,h6,p {
    font-family: "Cairo";
    /* font-weight: bold; */
}
.about h4{
    font-weight:bold ;
}
/* .companymission{
    padding-left: 70px;
} */

.leadimg{
        background-image: url('./Images/laptop_working.jpg');
        background-size: 100% 100%;
        width: 100%; 
        /* height: 350px;    */
}

.leadheader{
        padding-top: 120px;
        padding-bottom: 100px;
        box-sizing: border-box;
}
.about h4{
    font-size: 26px;
}
.about h4::after  {
    content: '';
    background:#E02B59;
    display: block;
    height: 2px;
    width: 125px;
    margin: 11px  1px;
}
.team h3::after{
    content: '';
    background:#E02B59;
    display: block;
    height: 2px;
    width: 130px;
    margin: auto auto;
}
.concept h3::after{
    content: '';
    background:#E02B59;
    display: block;
    height: 2px;
    width: 255px;
    margin: 11px  1px;
}
.concept h4::after{
    content: '';
    background:#0D4988;  
    display: block;
    height: 2px;
    width: 900px;
    margin: 11px  1px;
}
.companymission h4 , .concept h4{
    color: #E02B59;
    font-weight: bold;
}
.team-card{
    margin: auto auto;
}
.team img{
/* border-radius: 50%; */
width: 78%;
height: 250px;

/* border: 3px solid navy; */
}
.team-card h6{
    font-size: 10px;
    /* padding-bottom: -100px; */
}
.team-card p{
    font-size: 8px;
}
.team-content{
    background: #E02B59;
    display: inline-block;
    height: 35px;
    margin-left: 20px;
    margin-top: -5px;
    position: relative;
    width: 160px;
}
.team-content::before{
    
        border-bottom: 35px solid #E02B59;
        border-left: 80px solid transparent;
        border-right: 80px solid transparent;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        top: -35px;
        width: 0;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgb(222, 34,69);
  }

  
  .team-card:hover .overlay {
    opacity: 1;
    
  }
  .pay{
      margin-top: -6px;
  }
  /* .companymissionrow1{
      margin-left: 47px ;
  } */
  /* .teamrow h5{
      padding-top: 600px;
  } */
@media screen and (max-width: 768px){
    .leadheader1{
       padding-top: 65px;
    }
    .traingle{
        display: none;
    }
    .abouthr{
        width: 86%;
    }
     .concept h4::after{
        display: none;
    }
    /* .concept h4::after{
       
        margin: auto auto;
    } */
    .concept h3::after{
       width: 95%;
    }
   
    .leadimg_mobile{
        background-image: url('./Images/leadimg_mobile.jpg');
        background-size: 100% 100%;
        width: 100%;   
        height: 360px; 
       
}

    /* .companymission{
        padding-left: -400px;
    } */
}
.submit{
   
}
@media(min-width: 1024px) and (max-width:1024px){
    .apply3, .imgdiv img{
           margin-left: -160px;
    }
    .companymission_row2 img{
         height: 25vh;
    }
    .concept h4::after{
width: 93%;
    }
}
@media (min-width: 769px) and (max-width:1023px){
    .apply3, .imgdiv img{
        margin-left: 100px;
 }
}