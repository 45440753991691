 /*.navbar{
     clip-path: polygon(
         0 0,
         100% 0,
         100% 84%,
         64% 84%,
         50% 100%,
         36% 84%,
         0% 84%

     );
} */
/* .navbar{
    margin-top: -10%;
} */
.navbar{
    margin-top: -115px;
   
}

.dropdown-menu{
    animation-name: attHover;
    animation-duration: 1s;
    animation-iteration-count: finite;
    
    
}
.dropdown:hover{
    animation-play-state: running;
    
}
.dropdown:hover>.dropdown-menu{
    display: block;
    
}
.dropdown-item:hover{
    background-color: #ccc;
    color: black;
}
.dropdown-item:active{
    background-color: none;
}
.myFinLoanDropdown{
    color: #003366;
    font-weight: bold;
}
/* .dropdown-toggle{
   
    color: black;
} */
.dropdown-menu{
    /* background-color: black; */
    /* color: black; */
    /*margin-top: 62px;*/
    
}
/* .dropdown:hover>.dropdown-menu{ 
    
    color: black;
} */
/* .dropdown-item:hover{
    background-color: black;
} */
  .navbar-brand span{
    color: red;
}
.logo img{
    width:17%;
    padding-right: 10px;
}

.logo1 h1{
    color: #003366;
    font-size: 25px;
    
}
/* .navbar-nav{
    margin-right: -120px;
} */
.nav-item{
    font-size: 15px;
   font-family: 'Times New Roman', Times, serif;
}
/* .nav-item>.active{
    background-color: #C0C0C0;
    color: green;
    
} */
.nav-link{
    font-size: 18px;
    /* font-weight: bolder; */
    font-weight: 500;
   /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;  */
   font-family: 'Cairo', sans-serif;
     /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;  */
    margin-right: 5px;
}

.apply1{
    background-color: rgb(222, 34,69);
    /* width: 88%; */
    font-weight: bold;
}
.login{
    background-color: rgb(19, 74, 133);
    width: 100%;
    font-weight: bold;
}
/* .navbar{
    background-color: red;
} */
/* .navbar:after{
    position: absolute;
    width: 30px;
    height: 30px;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    border-left: 0px solid #ffffff;
    top: 110%;
    left: 50%;
    margin-left: -25px;
    content: '';
    transform: rotate(45deg);
    margin-top: -25px;
  
    background:#ffffff;

} */



@media(max-width: 768px){
   
    .navbar{
        margin-top: 15px;
       
    }
    .logo1 h1{
        color: #003366;
        font-size: 28px;
        margin-left: -70px;
        
    } 
    .login{
        width: 30%;
    }
    .apply1{
        /* width: 45%; */
        margin-top: 10px;
       
    }
    .navbar img{
        width: 45%;
    margin: auto auto auto auto;
    }
    .navbar:after{
        display:none;
    }
    .navbar{
        border: none;
      
    }
}
/* @media(max-width: 1024px){
    .login{
        width: 15%;
    } 
    .apply1{
        width: 20%;
    }
} */