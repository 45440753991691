.corporateimg{
    background-image: url('./Images/Group 235.jpg');
    background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 340px;
    margin-top: -17px;
    
}
.tieup{
    background-image: url('./Images/bg.jpg');
    background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 107vh;
    /* margin-top: -17px; */
}
/* .benefitimg{
    background-image: url('./Images/Mask Group 5.jpg');
    background-size: 100% 100%;
      position: relative;
      height: 200vh;
      width: 100%;

    
} */
.corporatehearderset{
    padding-top: 165px;
}
.corporatehearderset h2{
font-size: 44px;
}
.corporatehearderset h2::after{
    content: '';
    background: #E02B59;
    display: block;
    margin-top: 100px;
    height: 4px;
    width: 255px;
    margin:  auto 1px;
}
/* .tieup{
    background-color: gainsboro;
 
} */
.trainglecorporate{
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -343px;
    margin-left: 367px;

}
.trainglecorporate::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}
.importance h5{
    font-size: 23px;
}
.tieup h5{
    font-size: 21px;
}
.tieup .card {
border-radius: 9%;
border: 3px solid #E02B59;
background-color: transparent;
}
.tieup .card-header{
    border-radius: 25px;
    background-color: #E02B59;
    margin-top: -3px;
    width: 101%;
    margin-left: -1px;
    font-size: 19px;
   
}

.tieup .card-body h5{
    padding: 5px;
}
.benefits .card{
    width: 90%;
    height: 130vh;
    
}
.benefits .card-header{
    background-color: #0D4988;
    width: 101%;
    margin-left: -1px;
    font-size: 20px;
    height: 24vh;
}
.benefits .card-body h6{
    font-size: 19px;
}
.benefitcardhr{
    width: 100%;
    border-color: grey;
    border-style: solid 1px;
  
}
.form{
    background-color: #E02B59;
    border-radius: 120px;
    width: 80%;
}
.enrollbutton{
    margin-left: 15px;
   background-color: white;
    border-radius: 30px;
    /* width: 13%; */
    font-weight: bold;
}
.importance h3::after {
    content: '';
    background: #E02B59;
    display: block;
    height: 2px;
    width: 420px;
    padding-top: 3px;
    margin-top: 5px;
    /* margin: 10px auto 1px; */
}
@media screen and (max-width: 768px){
    /* .benefitimg{
        background-image: none;
        position: absolute;
        height: none;
        width: none;
       
    
        
    } */
    .tieup{
        background-image: none;
        background-color: gainsboro;
        background-size: cover;
        position: relative;
        height: 190vh;
          
    }
    .corporateimg1{
        background-image: url('./Images/Group 252.jpg');
        background-size: 100% 100%;
        position: relative;
            width: 100%;
            height: 340px;
        margin-top: 17px;
        
    }
    .corporateimg1 h2{
        font-size:32px;
        margin-top:-22px;
    }
    .importance h5{
        font-size:19px;
    }
    .importance h3{
        font-size:27px;
    }
    .importance h3::after {
    display:none;
    }
    .corporatehearderset h2::after{
        width: 200px;

    }
    .tieup .card .card-header{
        font-size:16.5px;
    }
    .benefits .benefitcard1{
        width:110%;
        height: 850px;
        margin-left: -10px;
       
    }
    .benefits .benefitcard2{
        width:110%;
        height: 950px;
        margin-left: -10px;
       
    }
    .benefits h5{
        font-size: 18px;
       
    }
    .benefitheading{
       width: 110%;
        margin-left: -10px;
    }
    .benefits .card .card-header {
        font-size:17px;
    }
    .benefits .card .benefit-header{
        height:17vh;
    }
    .form{
        width:120%;
        margin-bottom: -50px;
        margin-left: -20px;
        
    }
    .form h5{
        font-size:13px;
        margin-top:4px;
    }
    .benefits .card{
        border: 1px solid black;
    }
}
@media(min-width: 1024px) and (max-width:1024px){
    .benefits .card{
        height: 73vh;
    }
    .benefits .card-header{
        height: 15vh;
    }
    .tieup{
        
            height: 52vh;
        
    }
    .tieup .card{
        height: 30vh;
    }
}
@media only screen and (min-width: 768px) and (max-width:1023px){
    
    .tieup{
        background-image: none;
        background-color: gainsboro;
        background-size: cover;
        position: relative;
        height: 65vh;
}
.benefitcard2 .card-header ,.benefits .card .benefit-header{
    height: 13vh;
}

.benefits .row{
    margin-left:-75px;
}
.benefits .card{
   
    height: 87vh;
    
}
.benefits .form{
    width: 90%;
    margin-left: 10%;
}
}
/* @media(min-width: 768px)and (max-width:768px){
 
    .tieup{
        background-image: none;
        background-color: gainsboro;
        background-size: cover;
        position: relative;
        height: 70vh;
    }
} */
@media only screen and (min-width: 320px) and (max-width:640px){
    .tieup{
        background-image: none;
        background-color: gainsboro;
        background-size: cover;
        position: relative;
        height: 190vh;
    }
}
@media only screen and (min-width: 374px) and (max-width:812px){
    .tieup{
        background-image: none;
        background-color: gainsboro;
        background-size: cover;
        position: relative;
        height: 180vh;/*height:150vh to height:180vh*/
    }
}