.associateimg{
    background-image: url('./Images/business-people-shaking-hands.jpg');
    background-size: 100% 100%;
position: relative;
    width: 100%;
    height: 360px;
    margin-top: -17px;
    
}
.steps {
    width: 75%;
    border-radius: 100px;
    background-color: #0D4988;
    margin-left: 90px;

}
.stepshand img{
    width: 75%;
    margin-top: 100px;
   
}
.traingleassociate{
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent ;
    border-top: 34px solid grey;
    margin-top: -363px;
    margin-left: 800px;

}
.traingleassociate::before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent ;
    border-top: 29px solid white;
    bottom: 20px;
    left: -40px;
    margin-bottom: -15px;
}
.associate_interesting h3{
    color: #0D4988;
}
.circle1 {
    border-radius: 50%;
    width: 45px;
    height: 46px;
    padding: 7px;
    background: #E02B59;
    /* border: 3px solid #000; */
    /* color: #000; */
    text-align: center;
    font: 32px Arial, sans-serif;
    
    
  }
  .formclose img{
      margin-top: 5px;
      
  }
  .thankyou img{
      width: 30%;
      margin-top: 50px;
  }
  .associatehearderset{
    padding-top: 165px;
}
.associatehearderset h2{
font-size: 44px;
}
.associatehearderset h2::after{
    content: '';
    background: #E02B59;
    display: block;
    margin-top: 100px;
    height: 4px;
    width: 240px;
    margin:  auto 1px;
}
.verticalline2{
    /* width: 50px; */
    /* height: 100px; */
    /* background-color: black; */
    border-left: 2px dotted #0D4988;
    margin-left: 150px;
    /* margin-top: 60px; */
    height: 20px;
}
.circlediv img{
    width: 400%;

        
}


.associate_name{
    margin-left: 79px;
    
}
.associte_model_form input{
    width: 50%;
}
.associate_mobile{
    margin-left: -2px;
}
.associate_birth{
   margin-left: -42px;     
}

  @media screen and (max-width: 768px){
      .associateimg{
        background-image: url('./Images/banner\ mo.jpg');
        background-size: 100% 100%;
    position: relative;
        width: 100%;
        height: 360px;
        margin-top: 17px; 
      }
      .associatehearderset h2{
        font-size: 38px;
        }
        .associatehearderset h2::after{
            content: '';
            background: #E02B59;
            display: block;
            margin-top: 100px;
            height: 4px;
            width: 200px;
            margin:  auto 1px;
        }
        .steps{
            width: 100%;
            margin: auto;
        }
        .steps h4{
          font-size: 15px;
        }
       /* .associate_interesting{
           background-color: rgb(220, 220, 220);
          
       } */
       .associatefooter{
           margin-top: -50px;
       }
       .associate_interesting h3{
        font-size: 20px;
    }
       .associate_interesting h5{
           font-size: 16px;
       }
       .associate_interesting h6{
        font-size: 20px;
        color: #0D4988;
        font-weight: bold;
        
    }
    .circle1 {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        padding: 7px;
        background: #E02B59;
       
        text-align: center;
        font: 32px Arial, sans-serif;
        
        
      }
      .circlediv img{
        width: 30px;
    }
    .associte_model_form{
        margin-left: -10px;
    }
    .associate_name{
        margin-left: 36px;
        margin-right: -12px;
    }
    .associte_model_form input{
        width: 64%;
    }
   
    .associate_mobile{
        margin-left: -1px;
    }
    .associate_mail{
        /* margin-left: -58px; */
        margin-left: -30px;
        /* position: fixed; */
    }
  }
  @media(min-width: 1024px) and (max-width:1024px){
      .circlediv img{
          width: 35px;
          /* margin-left: 300px; */
      }
      .associates_steps{
          margin-left: -40px;
      }
      .stepshand img{
            margin-top: 150px;
      }
      .steps{
          width: 80%;
      }
  }
  @media only screen and (min-width: 768px) and (max-width:1023px){
    .steps {
        width: 85%;
        border-radius: 100px;
        background-color: #0D4988;
        margin-left: 30px;
    
    }
    .circlediv img{
        margin-left: 32px;
    }
   
    .associate_interesting h3{
        margin-left: -65px;
        
    }
     .associate_interesting h5{
         margin-left: -60px;
     }
  }